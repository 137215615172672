<template>
  <div class="page">
    <navbar index="0" />
    <div class="banner" :style="initBannerHeight">
      <h1>诚派科技     凝力共赢</h1>
    </div>
    <div class="main-content">
        <!-- 产品体系 begain -->
        <div class="product-box" id="product">
          <section-title subtitle="专注将技术深度赋能行业发展，在多领域推出领先的创新技术和产品" title-style="color: #fff;" subtitle-style="color: #fff;">
            <span>P</span>RO<span>D</span>UCT/产品体系
          </section-title>
          <div class="products">
            <div class="items">
              <router-link to="/custom">
                <img src="../assets/img/customization.jpg" alt="">
              </router-link>
            </div>
            <div class="items">
              <router-link to="/product_1">
                <img src="../assets/img/standardization.jpg" alt="">
              </router-link>
            </div>
            <div class="items">
              <a href="http://www.huayu.net.cn" target="__blank">
                <img src="../assets/img/system_integration.jpg" alt="">
              </a>
            </div>
            <div class="items">
              <router-link to="/hr"> 
                <img src="../assets/img/human_resource.jpg" alt="">
              </router-link>
            </div>
          </div>
        </div>
        <!-- 产品 end -->

        <!-- 解决方案 begain -->
        <div class="solution-box" id="solution">
          <section-title subtitle="电商、金融、医疗、智能、航空、能源、传媒，深入各行业满足个性化需求">
            <span>S</span>OLU<span>T</span>ION/行业解决方案
          </section-title>
          <div class="solution">
            <div class="solution-content">
              <div class="item">
                <router-link to="/product_3">
                  <div class="item-img">
                      <img src="../assets/img/solution_4.jpg" alt="智慧能效碳足迹管理分析平台">
                  </div>
                  <div class="item-subtitle">浙江灏源科技有限公司</div>
                  <div class="item-title">智慧能效碳足迹管理分析平台</div>
                  <div class="item-desc">注重能源互联网创新型技术成果研发及应用，运用世界顶级咨询机构能源行业洞察力，致力于推动绿色智慧用能的创新型综合能源。</div>
                </router-link>
              </div>
              <div class="item">
                <router-link to="/product_2">
                  <div class="item-img">
                    <img src="../assets/img/solution_2.jpg" alt="可视化智慧管理平台">
                  </div>
                  <div class="item-subtitle">上海高屋科技有限公司</div>
                  <div class="item-title">可视化智慧管理平台</div>
                  <div class="item-desc">应用一套可视化智慧管理平台，将各后勤支撑部门所提供的服务有机地集中到一个统一的对外联系“窗口”。</div>
                </router-link>
              </div>
              <div class="item">
                <router-link to="/product_1">
                  <div class="item-img">
                    <img src="../assets/img/solution_3.jpg" alt="实验动物信息化管理解决方案">
                  </div>
                  <div class="item-subtitle">江苏集萃工业生物技术研究所有限公司</div>
                  <div class="item-title">实验动物信息化管理解决方案</div>
                  <div class="item-desc">多个系统对接，实现数据共享。从订购，生产、销售一体化。研发实验动物相关智能硬件。</div>
                </router-link>
              </div>
              <div class="item">
                <router-link :to="{path: 'case#custom', query: {caseTapIndex: 2, caseInfoIndex: 0}}">
                  <div class="item-img">
                    <img src="../assets/img/solution_1.jpg" alt="器官捐献系统及报表管理系统">
                  </div>
                  <div class="item-subtitle">东方医院吉安医院</div>
                  <div class="item-title">器官捐献系统及报表管理系统</div>
                  <div class="item-desc">东方医院吉安医院器官捐献系统。用户使用系统可以了解器官捐献须知，然后进行信息登记，然后提交申请。也支持取消申请，信息查询等功能。</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 end -->

        <!-- 核心价值 begain -->
        <div id="value">
          <coreValue />
        </div>
        <!-- 核心价值 end -->

        <!-- 合作伙伴 begain -->
        <div id="partner">
          <partner />
        </div>
        <!-- 合作伙伴 end -->

        <!-- 联系我们 begain -->
        <div id="contact">
          <contact />
        </div>
        <!-- 联系我们 end -->

    </div>
    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import partner from '@/components/partner'
  import contact from '@/components/contact'
  import coreValue from '@/components/coreValue'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'Home',
    computed: {
      initBannerHeight: () => {
        const W = 1920, H = 460, _W = window.screen.width
        return 'height: ' + (H * _W) / W + 'px'
      }
    },
    components: {
      navbar,
      contact,
      coreValue,
      partner,
      foot,
      sectionTitle
    }
  }
</script>

<style lang="scss" scoped>
  .page {
    background: url('../assets/img/banner_home.jpg') center top no-repeat;
    background-size: contain;
  }
  .banner {
    position: relative;
    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      line-height: 1;
      font-size: 60px;
      color: #fff;
    }
  }
  .main-content {
    position: relative;
    width: 100%;
    height: auto;
  }
  .product-box{
    .products {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 43px;
      width: 1225px;
      height: auto;
      font-size: 0;
      .items {
        flex: 0 0 606px;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
  .solution-box {
    padding-top: 100px;
    .solution {
      display: flex;
      .solution-content {
        width: 1200px;
        margin: 0 auto;
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .item {
          position: relative;
          margin-right: 30px;
          padding-bottom: 30px;
          flex: 1;
          box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2);
          &::after {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 10px;
            background-image: linear-gradient(to right, #2662FF, #579BDB);
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          .item-img {
            width: 100%;
            img {
              width: 100%;
            }
          }
          .item-subtitle {
            margin-top: 30px;
            padding: 0 15px;
            line-height: 1.4;
            font-size: 14px;
            color: #999;
          }
          .item-title {
            padding: 0 15px;
            line-height: 1.4;
            font-size: 18px;
            color: #2662FF;
          }
          .item-desc {
            margin-top: 20px;
            padding: 0 15px;
            line-height: 1.4;
            font-size: 14px;
            color: #999;
          }
        }
      }
    }
  }
</style>