<template>
  <div class="nav-wrapper" :class="absolute ? 'nav-absolute' : ''">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/img/logo.png" width="156" height="32" title="诚派科技" alt="诚派科技">
      </router-link>
    </div>
    <div class="nav-items">
      <div class="nav-item">
        <router-link to="/" class="item" :class="index == '0' ? 'on' : ''">首页</router-link>
      </div>
      <div class="nav-item">
        <router-link to="/about" class="item" :class="index == '1' ? 'on' : ''">关于我们</router-link>
        <ul class="sub-items">
          <li><a href="/about#about" :class="index == '1' && (subIndex == 'about' || subIndex == '') ? 'on' : ''">关于诚派</a></li>
          <li><a href="/about#culture" :class="index == '1' && subIndex == 'culture' ? 'on' : ''">公司文化</a></li>
          <li><a href="/about#industry" :class="index == '1' && subIndex == 'industry' ? 'on' : ''">行业合作</a></li>
          <li><a href="/about#partner" :class="index == '1' && subIndex == 'partner' ? 'on' : ''">合作伙伴</a></li>
          <li><a href="/about#contact" :class="index == '1' && subIndex == 'contact' ? 'on' : ''">联系我们</a></li>
        </ul>
      </div>
      <div class="nav-item">
        <router-link to="/custom" class="item" :class="index == '2' ? 'on' : ''">定制化开发</router-link>
        <ul class="sub-items">
          <li><a href="/custom#tech" :class="index == '2' && (subIndex == 'tech' || subIndex == '') ? 'on' : ''">技术流程</a></li>
          <li><a href="/custom#advant" :class="index == '2' && subIndex == 'advant' ? 'on' : ''">系统优势</a></li>
          <li><a href="/custom#value" :class="index == '2' && subIndex == 'value' ? 'on' : ''">我们的核心价值</a></li>
        </ul>
      </div>
      <div class="nav-item">  
        <a href="/product_1" class="item" :class="index == '3' ? 'on' : ''">产品标准化</a>
        <ul class="sub-items">
          <li><a href="/product_1" :class="index == '3' && subIndex == '0' ? 'on' : ''">实验动物信息化管理</a></li>
          <li><a href="/product_2" :class="index == '3' && subIndex == '1' ? 'on' : ''">可视化综合运维</a></li>
          <li><a href="/product_3" :class="index == '3' && subIndex == '2' ? 'on' : ''">智慧能效碳足迹分析管理平台</a></li>
        </ul>
      </div>
      <div class="nav-item">
        <a href="/case" class="item" :class="index == '4' ? 'on' : ''">成功案例</a>
        <ul class="sub-items">
          <li><a href="/case#product" :class="index == '4' && (subIndex == 'product' || subIndex == '') ? 'on' : ''">产品标准化案例</a></li>
          <li><a href="/case#custom" :class="index == '4' && subIndex == 'custom' ? 'on' : ''">定制化开发服务案例</a></li>
        </ul>
      </div>
      <div class="nav-item">
        <a href="/news" class="item" :class="index == '5' ? 'on' : ''">最新资讯</a>
        <ul class="sub-items">
          <li><a href="/news#news" :class="index == '5' && (subIndex == 'news' || subIndex == '') ? 'on' : ''">最新资讯</a></li>
          <!-- <li><a href="/news#jobs" :class="index == '5' && subIndex == 'jobs' ? 'on' : ''">招聘信息</a></li> -->
          <li><a href="/news#join" :class="index == '5' && subIndex == 'join' ? 'on' : ''">加入我们</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'navbar',
    props: {
      index: {
        type: [String, Number],
        default: '0'
      },
      sub: {
        type: [String, Number],
        default: ''
      },
      absolute: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        subIndex: this.sub
      }
    },
    watch: {
      $route (to) {
        if (to == '') {
          this.subIndex = 0
        } else {
          this.hashHandle(to.hash)
        }
      }
    },
    created () {
      this.hashHandle(this.$route.hash)
    },
    methods: {
      hashHandle (routeHash) {
        let subIndex = routeHash.split('#');
        if (subIndex.length == 2 && subIndex[1] != '') {
          this.subIndex = subIndex[1]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 1078px;
    height: 72px;
    &.nav-absolute {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 20;
    }
    .nav-items {
      display: flex;
      align-items: stretch;
      height: 100%;
      cursor: pointer;
      .nav-item{
        position: relative;
        a.item {
          display: inline-block;
          position: relative;
          margin: 20px 40px 0 40px;
          font-size: 18px;
          color: #fff;
        }
        &:hover .item::after, .item.on::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -14px;
          display: inline-block;
          width: 100%;
          height: 2px;
          background-color: #fff;
        }
        .sub-items {
          position: absolute;
          left: 50%;
          bottom: 1px;
          transform: translate(-50%, 100%);
          display: none;
          margin: 0;
          padding: 18px 12px;
          width: auto;
          text-align: center;
          // box-sizing: content-box;
          background-color: rgba(254, 254, 254, .6);
          border-radius: 4px;
          li {
            margin-bottom: 18px;
            &:last-child {
              margin: 0;
            }
            a {
              display: inline-block;
              font-size: 18px;
              color: #333;
              &:hover, &.on{
                color: #2C68FB;
              }
            }
          }
        }
        &:hover .sub-items {
          display: block;
        }
        &:nth-child(1) .sub-items{
          // width: 90px;
          width: 136px;
        }
        &:nth-child(2) .sub-items{
          // width: 50px;
          width: 100px;
        }
        &:nth-child(3) .sub-items{
          // width: 85px;
          width: 150px;
        }
        &:nth-child(4) .sub-items{
          // width: 160px;
          width: 260px;
        }
        &:nth-child(5) .sub-items{
          // width: 110px;
          width: 190px;
        }
        &:nth-child(6) .sub-items{
          // width: 50px;
          width: 100px;
        }
      }
    }
  }
</style>