<template>
  <div class="back-top">
    <div class="back-top-btn" @click="backTop" v-if="showBtn">
      <img src="../assets/img/backTop.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return { 
        showBtn: false
      }
    },
    mounted() {
      window.addEventListener("scroll", this.showbtn, true)
    },
    methods: {
      showbtn() {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.scrollTop = scrollTop
        if (scrollTop > 750) {
          this.showBtn = true
        } else {
          this.showBtn = false
        }
      },
      backTop() {
        var timer = setInterval(function () {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
          this.isTop = true;
          if (osTop === 0) {
            clearInterval(timer);
          }
        }, 30);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .back-top {
    position: fixed;
    bottom: 150px;
    right: 100px;
  }
</style>