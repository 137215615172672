<template>
  <div class="partner-box">
    <section-title subtitle="长期良好的合作伙伴关系，开创新业务，获取技术、资源，实现更快速成长">
      <span>P</span>ART<span>N</span>ER/合作客户
    </section-title>
    <div class="partner-content">
      <div class="item"><img src="../assets/img/partner_1.png" alt="添医学医" /></div>
      <div class="item"><img src="../assets/img/partner_2.png" alt="中国民生银行" /></div>
      <div class="item"><img src="../assets/img/partner_3.png" alt="集萃药康" /></div>
      <div class="item"><img src="../assets/img/partner_4.png" alt="中恒大耀" /></div>
      <div class="item"><img src="../assets/img/partner_5.png" alt="奥莱国信" /></div>
      <div class="item"><img src="../assets/img/partner_6.png" alt="江苏安防" /></div>
      <div class="item"><img src="../assets/img/partner_7.png" alt="海信" /></div>
      <div class="item"><img src="../assets/img/partner_8.png" alt="环特生物" /></div>
    </div>
  </div>
</template>

<script>
  import sectionTitle from '@/components/sectionTitle'
  export default {
    components: {
      sectionTitle
    }
  }
</script>

<style lang="scss" scoped>
  .partner-box {
    padding: 60px 0;
    background-color: #f7f7f7;
    .partner-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 77px;
      max-width: 1374px;
      width: 100%;
      .item{
        // flex: 1;
        &.item:nth-child(1) {
          margin-bottom: 31px;
        }
      }
    }
  }
</style>