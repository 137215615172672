<template>
  <div class="contact-box">
    <section-title title="CONTACT US／联系我们" />
    <div class="contact-content">
      <div class="address">
        <h3>诚派科技</h3>
        <p>CHENGPAI TECHNOLOGY</p>
        <p><a href="http://www.chengpai.net.cn">www.chengpai.net.cn</a></p>
        <br>
        <br>
        <p>座机：<a href="tel:02558862676">025-58862676</a></p>
        <p>手机：<a href="tel:13776513828">13776513828</a></p>
        <br>
        <br>
        <p>邮箱：<a href="mailto: liuxiaotong@chengpai.net.cn">liuxiaotong@chengpai.net.cn</a></p>
        <p class="address-info"><span>地址：</span><span>江苏省南京市浦口高新技术开发区星火E方826-827</span></p>
        <p>邮编：211800</p>
      </div>
      <div class="map">
        <img src="../assets/img/map.jpg" alt="诚派科技">
      </div>
    </div>
  </div>
</template>

<script>
  import sectionTitle from '@/components/sectionTitle'
  export default {
    components: {
      sectionTitle
    }
  }
</script>

<style lang="scss" scoped>
  .contact-box {
    padding: 150px 0;
    .contact-content {
      margin: 0 auto;
      margin-top: 121px;
      display: flex;
      justify-content: space-between;
      width: 1221px;
      .address {
        width: 380px;
        padding: 60px 40px;
        color: #333;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, .2);
        h3 {
          font-size: 26px;
        }
        p {
          margin-bottom: 5px;
          font-size: 16px;
          line-height: 1.4;
          color: #666;
          a {
            color: inherit;
          }
          &.address-info {
            display: flex;
            justify-content: space-between;
            span {
              display: inline-block;
              &:first-child {
                width: 70px;
              }
            }
          }
        }
      }
      .map {
        width: 754px;
      }
    }
  }
</style>