<template>
  <div class="core_value-box">
    <section-title title="CORE VALUE/我们的核心价值" subtitle="领先的软件定制服务，专业的服务，无限创造新价值" />
    <div class="core_value-content">
      <div class="item">
          <div class="item-img"><img src="../assets/img/core_value_1.png" alt="全栈自研技术"></div>
          <div class="item-title">全栈自研技术</div>
          <div class="item-desc">一整套大数据应用技术解决方案，围绕着大数据开发、大数据应用两个核心阶段展开</div>
      </div>
      <div class="item">
          <div class="item-img"><img src="../assets/img/core_value_2.png" alt="深刻的行业理解"></div>
          <div class="item-title">深刻的行业理解</div>
          <div class="item-desc">以丰富的行业经验、深刻的行业理解和产品化能力 助力行业客户解决核心需求问题</div>
      </div>
      <div class="item">
          <div class="item-img"><img src="../assets/img/core_value_3.png" alt="领先的创新能力"></div>
          <div class="item-title">领先的创新能力</div>
          <div class="item-desc">探索本质、执着追求，以世界级问题打磨创新和技术能力 突破已有框架，创新性发展智能技术</div>
      </div>
      <div class="item">
          <div class="item-img"><img src="../assets/img/core_value_4.png" alt="大规模实践验证"></div>
          <div class="item-title">大规模实践验证</div>
          <div class="item-desc">在城市管理、医疗健康、安全生产、互联网服务等场景实现规模化商业落地和与产业的深度融合</div>
      </div>
      <div class="item">
          <div class="item-img"><img src="../assets/img/core_value_5.png" alt="数据价值理念"></div>
          <div class="item-title">数据价值理念</div>
          <div class="item-desc">核心价值应用场景化，通过具体场景实现数据的价值 通过提炼海量信息实现先知般的预判和示警</div>
      </div>
    </div>
  </div>
</template>

<script>
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'coreValue',
    data() {
      return {
      }
    },
    components: {
      sectionTitle
    }
  }
</script>

<style lang="scss" scoped>
  .core_value-box {
    padding: 150px 0;
    .core_value-content {
      display: flex;
      margin: 0 auto;
      margin-top: 82px;
      width: 1189px;
      .item {
        margin-right: 40px;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }
        .item-img {
          margin: 0 auto;
          margin-bottom: 21px;
          width: 79px;
          height: 91px;
        }
        .item-title {
          margin-bottom: 21px;
          line-height: 1;
          font-size: 18px;
          color: #3E3F3F;
        }
        .item-desc {
          line-height: 20px;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
</style>