<template>
  <div class="title-box">
    <div class="title" :style="titleStyle">
      {{title}}
      <slot />
    </div>
    <div class="subtitle" v-if="subtitle != ''" :style="titleStyle">{{subtitle}}</div>
    <div class="title-bar" :style="{'--bgcolor': barColor}" v-else></div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      titleStyle: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      subtitleStyle: {
        type: String,
        default: ''
      },
      barColor: {
        type: String,
        default: '#2C68FF'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title-box {
    line-height: 1;
    text-align: center;
    .title {
      margin-bottom: 16px;
      font-size: 41px;
      color: #272828;
      span {
        color: #2C68FF;
      }
    }
    .subtitle {
      text-align: center;
      font-size: 20px;
      color: #999999;
    }
    .title-bar {
      margin: 0 auto;
      width: 120px;
      height: 10px;
      &::after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background-color: var(--bgcolor);
      }
    }
  }
</style>