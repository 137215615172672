<template>
  <div>
    <div class="footer-wrapper">
      <div class="footer-content">
        <div class="footer-item">
          <div class="nav-title">关于我们</div>
          <div class="nav-subtitle">
            <router-link to="/about#about">关于我们</router-link>
          </div>
          <div class="nav-subtitle">
            <router-link to="/about#culture">团队优势</router-link>
          </div>
          <div class="nav-subtitle">
            <a href="https://www.zhipin.com/gongsi/32d0c99fe77c875603Z42tW_.html?ka=search_rcmd_company_name_32d0c99fe77c875603Z42tW__custompage" target="__blank">人才招聘</a>
          </div>
        </div>
        <div class="footer-item">
          <div class="nav-title">知名客户</div>
          <div class="nav-subtitle">中国民生银行</div>
          <div class="nav-subtitle">哈尔滨银行</div>
          <div class="nav-subtitle">中国移动通信</div>
        </div>
        <div class="footer-item">
          <div class="nav-title">友情链接</div>
          <div class="nav-subtitle">
            <a href="http://www.huayu.net.cn" target="__blank">上海华钰科技有限公司</a>
          </div>
        </div>
        <div class="footer-item">
          <div class="nav-title">客服热线<br><br>025-58862676</div>
          <div class="nav-subtitle">工作时间: 08.30-17.30 周一至周五</div>
          <div class="nav-sns">
            <div>
              <a href="tencent://Message/?Uin=969864508"><img src="../assets/img/qq.png" alt=""></a>
            </div>
            <div>
              <img src="../assets/img/wechat.png" alt="">
              <div class="qr-code">
                <img src="../assets/img/wechat.jpg" class="img-fluid" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="footer-item">
          <img src="../assets/img/QR.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © 2015.版权所有 诚派科技南京有限公司 All rights reserved.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn" target="__blank">苏ICP备14018892号</a></p>
    </div>

    <backTop />
  </div>
</template>

<script>
  import backTop from '@/components/backTop'
  export default {
    name: 'foot',
    components: {
      backTop
    }
  }
</script>

<style lang="scss" scoped>
  .footer-wrapper {
    padding: 48px 0;
    width: 100%;
    background-color: #2C3039;
    .footer-content {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      width: 1221px;
      .footer-item {
        // flex: 0 0 152px; //211
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        padding: 5px 59px;
        line-height: 1;
        border-right: 1px solid #7e7e7e;
        &:last-child, &:nth-child(4) {
          border: 0;
        }
        .nav-title {
          margin-bottom: 22px;
          line-height: 1;
          font-size: 16px;
          color: #fff;
        }
        .nav-subtitle {
          margin-bottom: 16px;
          font-size: 14px;
          color: #a8a8a8;
          &:last-child {
            margin: 0;
          }
          a {
            color: inherit;
          }
        }
        .nav-sns {
          display: flex;
          div {
            margin-right: 6px;
            cursor: pointer;
            &:last-child {
              position: relative;
              .qr-code {
                display: none;
                position: absolute;
                top: 0;
                right: -110px;
                padding: 5px;
                width: 100px;
                height: 100px;
                background-color: #fff;
              }
            }
            &:hover {
              .qr-code {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    padding: 40px 0;
    background-color: #2C3039;
    border-top: 1px solid #7e7e7e;
    color: #a8a8a8;
    p {
      text-align: center;
      line-height: 1;
      font-size: 14px;
      color: #a8a8a8;
    }
    a {
      color: #a8a8a8;
    }
  }
</style>