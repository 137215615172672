import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  {
    path: '/hr',
    name: 'Hr',
    component: () => import('@/views/HR.vue')
  },
  {
    path: '/custom',
    name: 'Custom',
    component: () =>  import('@/views/Custom.vue')
  },
  {
    path: '/product_1',
    name: 'Product1',
    component: () =>  import('@/views/product/Product_1.vue')
  },
  {
    path: '/product_2',
    name: 'Product2',
    component: () =>  import('@/views/product/Product_2.vue')
  },

  {
    path: '/product_3',
    name: 'Product3',
    component: () =>  import('@/views/product/Product_3.vue')
  },
  {
    path: '/case',
    name: 'Case',
    component: () =>  import('@/views/Case.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () =>  import('@/views/News.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  srcollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        x: 0,
        y: 0
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
